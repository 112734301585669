<script>
export default {
  props: {
    historie: Object,
  },
  computed: {
    getTime() {
      const [date, thisMoment] = [new Date(this.historie.date), new Date()];
      let [diff, response] = [(thisMoment - date) / 1000, ""];
      if (diff < 60) {
        response = "pocos segundos";
      } else if (diff < 3600) {
        response = Math.round(diff / 60) + " minutos";
      } else if (diff < 86400) {
        response = Math.round(diff / 3600) + " horas";
      } else {
        response = Math.round(diff / 86400);
        response += diff < 86400 * 2 ? " día" : " días";
      }
      return "hace " + response;
    },
  },
  methods: {
    goToCase() {
      if (this.$userData.role === "monitor" && this.historie.asignament._id !== this.$userData._id) return;
      if (this.$userData.role === "coordinator" && this.historie.whoCreate.city !== this.$userData.city) return;
      this.$router.push(`/helpdesk/detailsCase?id=${this.historie._id}`);
    },
  },
};
</script>

<template>
  <div class="historieCard" @click="goToCase">
    <div class="historieCard__wrapperr">
      <div class="historieCard__infoHistorie">
        <Avatar class="historieCard__avatar" :user="historie.whoCreate" />
        <div :class="['historieCard__userData']">
          <span class="historieCard__name">{{ historie.whoCreate.user }}</span>
          <div class="historieCard__info">
            <span class="historieCard__name">Responsable:</span>
            <span>{{ " " + historie.asignament.user }}</span>
          </div>
        </div>
        <span class="historieCard__timeInfo">{{ getTime }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.historieCard {
  cursor: pointer;
  &__wrapperr {
    padding: 5px 15px;
    background: #f3f3f3;
    border-radius: 27px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background: rgba(242, 189, 189, 0.15);
      transition: 0.3s ease-in-out;
    }
  }
  &__infoHistorie {
    @include Flex(row, space-between);
  }
  &__avatar {
    width: 37px;
    height: 37px;
    border: 1px solid $white;
  }
  &__userData {
    max-width: calc(100% - 110px);
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    margin: 0 $mpadding;
    text-overflow: ellipsis;
    overflow: auto;
  }
  &__name {
    font-size: 1em;
    color: black;
  }
  &__info {
    font-size: 0.9em;
    color: $chicago;
  }
  &__timeInfo {
    width: 50px;
    font-size: 0.8em;
  }
  @media screen and (min-width: $desktop_width) {
    &__userData {
      max-width: calc(100% - 150px);
    }
    &__timeInfo {
      width: 80px;
    }
  }
}
</style>
